import { isEmpty, uniq } from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';
import { logEvent } from '../../../../analytics';
import { useUploadFile } from '../../../../components/hooks/fileUpload';
import {
  IStorybook,
  IStorybookPage,
  LoadingStatus,
  SBMetadata
} from '../../../../interfaces';
import { DID_CLICK_BUTTON, DID_DELETE_FILE } from '../../../../utils/constants';
import { getIdentityId } from '../../../../utils/identity';
import { deleteObjectFromS3 } from '../../../clientSideServices/aws';
import { getLibrarySceneFile } from '../../../clientSideServices/library';
import {
  getPageConfigJson,
  HubPageConfig,
  PageConfigContext,
  saveHubPageConfigJson
} from '../../../clientSideServices/pageConfig';
import AddButton from '../../Common/AddButton';
import DeleteButton from '../../Common/DeleteButton';
import { HubContext } from '../../HubContext';
import { FETCH_EDITOR_FILES } from '../../Polotno/FileUploadSection/UploadContainer';
import ContentItem, { ConvertingThumbnail } from './ContentItem';
import ContentManagerPopup from './ContentManagerPopup';
import ContentManagerViewer from './ContentManagerViewer';
import ContentUploader from './ContentUploader';
import PagePreviewPopup from '../Common/PagePreviewPopup';
import { actionHubAlertError } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { getActivatedStorybookById } from '../../../clientSideServices/storybook';
import { getSceneByIdForCheck } from '../../../../clientSideServices/scene';

export type SBContentManagerTab = 'scene' | 'storybook' | 'file';

const bucket = 'storiez-data';

export interface ContentManagerItem {
  type: SBContentManagerTab;
  id: string;
  payload?: IStorybook;
}

export interface IFilesInUpload {
  file: File;
  name: string;
  key: string;
  metadata?: SBMetadata;
}

export type FileSupportedType = 'image' | 'video';

export const getAcceptTypes = (type: FileSupportedType[]) => {
  if (type?.length === 1) {
    if (type[0] === 'image')
      return 'image/jpeg, image/png, image/gif, application/pdf';
    if (type[0] === 'video') return 'video/mp4, video/quicktime';
  }
  return 'image/jpeg, image/png, image/gif, application/pdf, video/mp4, video/quicktime';
};

type Props = {
  contentId: string;
  context: PageConfigContext;
  onSelectItems: (sb: IStorybook[]) => void;
  selectedItem?: string;
  currentPages?: IStorybookPage[];
  onDelete?: (id: string) => void;
  inlineViewer?: boolean;
  fileType?: FileSupportedType[];
  autoAddNewItem?: boolean;
  disabled?: boolean;
  addAllButton?: boolean;
};

export const ContentManagerPanel = ({
  contentId,
  context,
  onSelectItems,
  selectedItem,
  currentPages,
  onDelete,
  inlineViewer,
  fileType = ['image', 'video'],
  autoAddNewItem,
  files,
  setFiles,
  disabled,
  addAllButton
}: Props & { files: File[]; setFiles: (files: File[]) => void }) => {
  const { user } = React.useContext(HubContext);
  const userId = getIdentityId() || user?.id;

  const [filesContent, setFilesContent] = React.useState<IStorybook[]>([]);
  const [activeTab, setActiveTab] = React.useState<SBContentManagerTab>(null);
  const [keywords, setKeywords] = React.useState('');
  const [loading, setLoading] = React.useState(LoadingStatus.INITIAL);
  const { unconvertedFiles, onFileConverted } = useUploadFile();
  const [editorConfig, setEditorConfig] = React.useState<HubPageConfig>(null);
  const [contentItems, setContentItems] = React.useState<ContentManagerItem[]>(
    []
  );
  const [isConfirming, setIsConfirming] = React.useState(null);
  const [contentViewer, setContentViewer] = React.useState<IStorybook>(null);
  const [preview, setPreview] = React.useState<IStorybook>(null);

  const uploadContext = `editor-${contentId}`;

  const logClick = (payload: { [key: string]: any }) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, payload);
  };

  const handleViewItem = (item: IStorybook) => {
    if (disabled) return;
    if (item.pages.length > 1 && inlineViewer) {
      setContentViewer(item);
    } else {
      setPreview(item);
    }
  };

  const handleSelectItem = (item: IStorybook) => {
    onSelectItems([item]);
  };

  const handleOpenPopup = () => {
    logClick({ button: 'Add Content' });
    setActiveTab('scene');
    setKeywords('');
  };

  const getFilesContent = (silentLoad?: boolean) => {
    if (!contentId) return;
    !silentLoad && setLoading(LoadingStatus.LOADING);

    logEvent(FETCH_EDITOR_FILES, FETCH_EDITOR_FILES, {
      contentId,
      context,
      status: 'Loading'
    });

    getLibrarySceneFile({ sceneId: uploadContext, asStorybook: true })
      .then((res) => {
        setFilesContent(res);

        setContentItems((state) => {
          const newItems = res.filter(
            (r) => state.map((c) => c.id).indexOf(r.id) === -1
          );
          return [
            ...newItems.map((item) => ({
              id: item.id,
              payload: item,
              type: 'file' as SBContentManagerTab
            })),
            ...state
          ];
        });

        const uploadedSceneUniqueKey = uniq(res.map((r) => r.id));
        onFileConverted(uploadedSceneUniqueKey);
        !silentLoad && setLoading(LoadingStatus.LOADED);
        logEvent(FETCH_EDITOR_FILES, FETCH_EDITOR_FILES, {
          contentId,
          context,
          status: 'Successful'
        });
      })
      .catch((e) => {
        logEvent(FETCH_EDITOR_FILES, FETCH_EDITOR_FILES, {
          contentId,
          context,
          status: 'Error',
          error: e
        });
        !silentLoad && setLoading(LoadingStatus.LOADED);
      });
  };

  const handleSaveContent = (items: ContentManagerItem[]) => {
    setContentItems(items);
    saveHubPageConfigJson({
      userId,
      config: {
        ...(editorConfig || {}),
        context: PageConfigContext.STORYBOOK_DESIGNER,
        content: items,
        id: contentId
      }
    });
  };

  const handleConfirm = async (items: ContentManagerItem[]) => {
    setIsConfirming(true);
    const contents: ContentManagerItem[] = JSON.parse(JSON.stringify(items));
    for (let index = 0; index < contents.length; index++) {
      const element = contents[index];
      if (element.type === 'storybook' && !element.payload.pages) {
        try {
          const sbData = await getActivatedStorybookById(element.id);
          if (sbData) {
            element.payload.pages = sbData.pages;
          }
        } catch (error) {
          console.log(error);
        }
      } else if (element.type === 'scene' && element?.payload?.pages) {
        try {
          const newPages = await Promise.all(
            element?.payload?.pages?.map(async (page) => {
              const sceneData = await getSceneByIdForCheck(page.id);
              if (sceneData) {
                return {
                  ...page,
                  settings: sceneData.data.content?.settings || page.settings,
                  url: sceneData.data.content?.url || page.url
                };
              }
              return {
                ...page
              };
            })
          );

          if (newPages) {
            element.payload.pages = newPages;
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    const newItems = contents.filter(
      (content) =>
        !contentItems.map((item) => item.id).includes(content.id) &&
        !currentPages?.map((page) => page.id).includes(content.id) &&
        !currentPages
          ?.map((page) => page.url)
          .includes(content.payload.pages?.[0]?.url)
    );

    if (autoAddNewItem && newItems.length) {
      const itemsPayload = newItems
        .filter((item1) => item1.type !== 'storybook')
        .map((item2) => item2.payload);
      const singlePagePayload = itemsPayload.filter(
        (item) => item.pages.length === 1
      );
      onSelectItems(singlePagePayload);
    }
    setIsConfirming(false);
    setActiveTab(null);
    handleSaveContent(contents);
  };

  const deleteFile = (id: string) => {
    deleteObjectFromS3(bucket, id).then(() => {
      setFilesContent(filesContent.filter((f) => f.id !== id));
      logEvent(DID_DELETE_FILE, DID_DELETE_FILE, { bucket, key: id });
      if (contentViewer?.id === id) {
        setContentViewer({} as IStorybook);
      }
    });
  };

  const handleDelete = (id: string, isFile?: boolean) => {
    if (isFile) {
      deleteFile(id);
    }
    handleSaveContent(contentItems.filter((item) => item.id !== id));
    onDelete?.(id);
  };

  React.useEffect(() => {
    if (contentId) {
      getFilesContent();
      if (!editorConfig) {
        getPageConfigJson({
          id: contentId,
          onSuccess: (res) => {
            setEditorConfig(res);
            setContentItems(res.content);
          },
          onError: () => {
            saveHubPageConfigJson({
              userId,
              config: {
                context,
                content: [],
                id: contentId
              }
            });
          }
        });
      }
    }
  }, [contentId]);

  React.useEffect(() => {
    let fetching;
    if (unconvertedFiles.length > 0) {
      fetching = setInterval(() => {
        getFilesContent(true);
      }, 5000);
    } else {
      fetching && clearInterval(fetching);
    }
    return () => {
      clearInterval(fetching);
    };
  }, [unconvertedFiles]);

  const panelHeader = (
    <div className="panel-header">
      <div className="d-flex flex-wrap align-items-center">
        <span className="panel-label">Content Manager</span>
        <div className="add-full">
          <AddButton
            darkMode={true}
            onClick={handleOpenPopup}
            label="Add Content"
          />
        </div>
        <div className="add-sm">
          <AddButton darkMode={true} onClick={handleOpenPopup} label="Add" />
        </div>
      </div>
      <style jsx>{`
        .panel-header .d-flex {
          justify-content: space-between;
        }
        .panel-content {
          flex-wrap: wrap;
          align-content: flex-start;
        }
        .panel-header :global(button) {
          min-width: 30px;
        }
        .add-full {
          display: none;
        }
        @media (min-width: 1400px) {
          .add-full {
            display: block;
          }
          .add-sm {
            display: none;
          }
        }
      `}</style>
    </div>
  );

  return (
    <>
      <div className={`ContentManager`}>
        {panelHeader}
        {!disabled && (
          <ContentUploader
            fileType={fileType}
            context={uploadContext}
            loadFiles={getFilesContent}
            loading={loading}
            files={files}
            setFiles={setFiles}
          />
        )}

        <div className="panel-content">
          <div className="content-container">
            {unconvertedFiles?.map?.((file) => (
              <div key={file.key}>
                <ConvertingThumbnail fileKey={file.key} />
              </div>
            ))}
            {contentItems.map((item) => {
              const id = item.id;
              return (
                <div key={id}>
                  <ContentItem
                    onSelect={handleSelectItem}
                    onView={handleViewItem}
                    selectedItem={selectedItem}
                    filesContent={filesContent}
                    id={id}
                    type={item.type}
                    onError={() => handleDelete(id, item.type === 'file')}
                    loadingFileStatus={loading === LoadingStatus.LOADING}
                    currentPages={currentPages}
                  />
                  <DeleteButton
                    onClick={() => handleDelete(id, item.type === 'file')}
                    yPosition="top"
                    indent="5px"
                    popupWidth={180}
                    floatingPopup={true}
                    noConfirmation={true}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {activeTab && (
        <ContentManagerPopup
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentContentItems={contentItems}
          onConfirm={handleConfirm}
          isConfirming={isConfirming}
          keywords={keywords}
          setKeywords={setKeywords}
          autoClose={false}
        />
      )}
      {preview && (
        <PagePreviewPopup
          storybook={preview}
          onClose={() => setPreview(null)}
          onSelect={handleSelectItem}
        />
      )}
      {contentViewer && (
        <ContentManagerViewer
          onSelect={handleSelectItem}
          onView={handleViewItem}
          content={contentViewer}
          onClose={() => setContentViewer(null)}
          currentPages={currentPages}
          onAddAll={addAllButton ? onSelectItems : undefined}
        />
      )}
      <style jsx>{`
        .content-container :global(.author) {
          font-size: 12px;
        }
        .ContentManager {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: no-wrap;
        }
        .ContentManager :global(.panel-header) {
          height: 40px !important;
          min-height: 40px !important;
        }
        .panel-content {
          display: flex;
        }

        :global(.upload-container),
        :global(.upload-container) {
          height: auto;
        }

        .content-container {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 0;
          align-content: flex-start;
          width: 100%;
          flex-grow: 1;
        }
        .content-container > div {
          width: 50%;
          padding: 8px;
          position: relative;
        }
        .content-container :global(.DeleteButton) {
          position: absolute !important;
          top: 2px;
          right: 2px;
          background: #fff;
          border-radius: 3px;
          display: none;
          box-shadow: 0 0 3px rgb(0 0 0 / 30%);
        }
        .content-container > div:hover :global(.DeleteButton) {
          display: block;
        }

        @media (max-width: 1400px) {
          .content-container > div {
            width: 100%;
            padding: 8px;
            position: relative;
          }
        }
      `}</style>
    </>
  );
};

const ContentManager = (props: Props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = React.useState<File[]>([]);
  return (
    <Dropzone
      noClick
      onDrop={(f, fileRejection) => {
        if (fileRejection && !isEmpty(fileRejection)) {
          const err = fileRejection[0].errors?.[0];
          if (err) dispatch(actionHubAlertError(`Error: ${err.message}`));
        }
        setFiles(f);
      }}
      multiple={true}
      accept={getAcceptTypes(props.fileType)}
    >
      {({ getRootProps, isDragActive }) => (
        <div
          className={`dropzone h-100 w-100 ${files.length ? 'hasFiles' : ''} ${
            isDragActive ? 'dragActive' : ''
          }`}
          {...getRootProps({ className: 'dropzone h-100' })}
        >
          <ContentManagerPanel {...props} files={files} setFiles={setFiles} />
          <style jsx>{`
            .dragActive :global(.panel-content),
            .hasFiles :global(.panel-content) {
              display: none;
            }

            .dragActive :global(.upload-container),
            .hasFiles :global(.upload-container) {
              height: 100%;
            }
          `}</style>
        </div>
      )}
    </Dropzone>
  );
};

export default ContentManager;
