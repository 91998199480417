import React from 'react';
import { LoadingStatus } from '../../../../interfaces';
import LoadingPlaceHolder from '../../Common/LoadingPlaceHolder';
import FileUpload from '../../Common/FileUploader/FileUpload';

import { useUploadFile } from '../../../../components/hooks/fileUpload';
import { FileSupportedType, getAcceptTypes } from '.';
const bucket = 'storiez-data';
const filePath = 'content/uploaded/libraries';

const ContentUploader = ({
  loading,
  loadFiles,
  context,
  fileType,
  files,
  setFiles
}: {
  loadFiles: () => void;
  loading: LoadingStatus;
  context: string;
  fileType?: FileSupportedType[];
  files?: File[];
  setFiles?: (files: File[]) => void;
}) => {
  const { addFilesToUpload } = useUploadFile();
  const handleUpload = (fileList: { file: File; name: string }[]) => {
    addFilesToUpload(
      fileList.map((file) => ({
        ...file,
        key: `${filePath}/${context}/${new Date().toISOString()} ${file.name}`,
        bucket
      }))
    );
  };

  const dropInfo = (
    <FileUpload
      onClose={() => {
        setFiles([]);
      }}
      onUpload={(f) => {
        handleUpload(f);
      }}
      files={files}
      type="inline"
      multipleUpload={true}
      onDeleteFile={(index) => setFiles(files.filter((_, i) => i !== index))}
      onUpdateFileList={setFiles}
      rejectDuplicates={true}
      hideCloseButton={true}
      acceptTypes={getAcceptTypes(fileType)}
    />
  );
  const loadingInfo = (
    <LoadingPlaceHolder loadingStatus={loading}>
      <p>Oops, something wrong happen.</p>
      <button className="btn btn-sm btn-outline-dark" onClick={loadFiles}>
        Try Again
      </button>
    </LoadingPlaceHolder>
  );

  return (
    <>
      {loading === LoadingStatus.LOADING && loadingInfo}
      {loading === LoadingStatus.LOADED && (
        <div className="upload-container">{dropInfo}</div>
      )}
      <style jsx>{`
        .upload-container :global(.dropzone) {
          font-size: 13px;
          padding-bottom: 0;
          border-radius: 0 !important;
        }
        .upload-container :global(.item-container) {
          width: 90% !important;
        }
        .upload-container :global(p:first-child) {
          margin-bottom: 0;
        }
      `}</style>
    </>
  );
};

export default ContentUploader;
